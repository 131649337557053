import {createSlice} from '@reduxjs/toolkit';
import {getScript} from "../../utils/data";
import {store} from "../store";
import * as Util from "./util";

const initialState = {
    cached: false,
    filters: Util.readLocalFilters("campaignFilters"),
    stat: {
        score: undefined,
        totalSpend: undefined,
        spendRate: undefined,
        roas: undefined,
        roasRate: undefined,
        totalSales: undefined,
        salesRate: undefined,
        cpm: undefined,
        cpmRate: undefined
    },
    trendData: undefined,
    salesData: {
        rate: undefined,
        retailers: []
    },
    dataFrame: [],
    loading: {
        stat: false,
        trendData: false,
        salesData: false,
        dataFrame: false
    },

};

export const slice = createSlice({
    name: 'campaign',
    initialState,
    reducers: {
        setCached(state, action) {
            state.cached = action.payload
        },
        setFilters(state, action) {
            state.cached = false
            state.filters = action.payload
            Util.saveFilters("campaignFilters", action.payload)
        },
        setStat(state, action) {
            state.stat = action.payload
        },
        setTrendData(state, action) {
            state.trendData = action.payload
        },
        setSalesData(state, action) {
            state.salesData = action.payload
        },
        setDataFrame(state, action) {
            state.dataFrame = action.payload
        },
        setLoading(state, action) {
            const {name, loading} = action.payload
            state.loading[name] = loading
        }

    }
});

export default slice.reducer;

const getFilterBody = (table = "retail_campaign_massaged") => {
    const message = []
    const campaignName = store.getState().campaign.filters.campaignName
    if (campaignName !== "All") {
        message.push({
            "field": "campaign_name",
            "operator": "eq",
            "value": campaignName,
            "type": "string"
        })
    }
    const retailerName = store.getState().campaign.filters.retailerName
    if (retailerName !== "All") {
        message.push({
            "field": "retailer",
            "operator": "eq",
            "value": retailerName,
            "type": "string"
        })
    }


    const campaignLineitem = store.getState().campaign.filters.campaignLineitem
    if (campaignLineitem !== "All") {
        message.push({
            "field": "lineitem",
            "operator": "eq",
            "value": campaignLineitem,
            "type": "string"
        })
    }

    const tacticType = store.getState().campaign.filters.tacticType
    if (tacticType !== "All") {
        message.push({
            "field": "tactice_site",
            "operator": "eq",
            "value": tacticType,
            "type": "string"
        })
    }

    const brandName = store.getState().campaign.filters.brand
    if (brandName !== "All") {
        message.push({
            "field": "primary_brand",
            "operator": "eq",
            "value": brandName,
            "type": "string"
        })
    }

    return {[table]: message}
}

export function getStat() {
    return async dispatch => {
        if (!store.getState().campaign.cached) {
            dispatch(slice.actions.setLoading({name: 'stat', loading: true}));
            try {
                const orgData = await getScript("CampaignPage_Stats", getFilterBody())
                dispatch(slice.actions.setStat({
                    score: orgData["Score"],
                    totalSpend: orgData["Total Spend"],
                    spendRate: orgData["MoM Change in Spend"],
                    roas: orgData["RoAS"],
                    roasRate: orgData["MoM Change in RoAS"],
                    totalSales: orgData["Total Sales"],
                    salesRate: orgData["MoM Change in Sales"],
                    cpm: orgData["CPM"],
                    cpmRate: orgData["MoM Change in CPM"]
                }));
            } catch (error) {
                console.log(error)
            }
            dispatch(slice.actions.setLoading({name: 'stat', loading: false}));
            dispatch(slice.actions.setCached(true))
        }
    };
}

export function getTrendData() {
    return async dispatch => {
        if (!store.getState().campaign.cached) {
            dispatch(slice.actions.setLoading({name: 'trendData', loading: true}));
            try {
                const orgData = await getScript("CampaignPage_Trend_Line", getFilterBody())
                dispatch(slice.actions.setTrendData(orgData['output table']));
            } catch (error) {
                console.log(error)
            }
            dispatch(slice.actions.setLoading({name: 'trendData', loading: false}));
            dispatch(slice.actions.setCached(true))
        }
    };
}

export function getSalesData() {
    return async dispatch => {
        if (!store.getState().campaign.cached) {
            dispatch(slice.actions.setLoading({name: 'salesData', loading: true}));
            try {
                const orgData = await getScript("CampaignPage_PieChart", getFilterBody())
                dispatch(slice.actions.setSalesData({
                    rate: orgData['MoM Change in Sales'],
                    retailers: Object.values(orgData['Total Sales by Retailer']).map((item) => {
                        return ({
                            name: item['retailer'],
                            normalizedTotalSales: item['Normalized_totalsales'],
                            percentage: item['Pertage_of_total'],
                            rate: item['MoM_Change'],
                        })
                    })
                }));
            } catch (error) {
                console.log(error)
            }
            dispatch(slice.actions.setLoading({name: 'salesData', loading: false}));
            dispatch(slice.actions.setCached(true))
        }
    }
}

export function getDataFrame() {
    return async dispatch => {
        if (!store.getState().campaign.cached) {
            dispatch(slice.actions.setLoading({name: 'dataFrame', loading: true}));
            try {
                const orgData = await getScript("CampaignPage_DataFrame", getFilterBody())
                dispatch(slice.actions.setDataFrame(Object.values(orgData).map((item, index) => {
                    return ({
                        id: index,
                        retailer: item["Retailer"],
                        campaignName: item["Campaign Name"],
                        brand: item["Brand"],
                        tactic: item["Tactic"],
                        campaignStart: item["Campaign Start"],
                        campaignEnd: item["Campaign End"],
                        impressions: item["Impressions"],
                        spend: item["Spend"],
                        totalSales: item["Total Sales"],
                        cpm: item["CPM"],
                        roas: item["ROAS"],
                        normalizedRoas: item["Normalized_ROAS"],
                        effectivenessScore: item["Effectiveness Score"],
                    })
                })))
            } catch (error) {
                console.log(error)
            }
            dispatch(slice.actions.setLoading({name: 'dataFrame', loading: false}));
            dispatch(slice.actions.setCached(true))
        }
    };
}