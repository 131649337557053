import {createSlice} from '@reduxjs/toolkit';
import {getScript} from "../../utils/data";
import {store} from "../store";

const initialState = {
    cached: false,
    campaign: {
        name: undefined,
        roas: undefined,
        score: undefined,
        roasByRetailer: {
            amazon: undefined,
            instacart: undefined,
            kroger: undefined,
            target: undefined,
            walmart: undefined,
        },
        topRetailer: undefined,
        topRetailerRoasByTactic: {
            roas: {
                offsiteDisplay: undefined,
                onsiteDisplay: undefined,
                search: undefined,
            }
        },
        recommendation: undefined,
    },
    product: {
        title: undefined,
        retailerScores: {
            amazon: undefined,
            kroger: undefined,
            target: undefined,
            walmart: undefined,
        },
        competingBrandScores: [],
        recommendation: undefined,
    },
    loading: {
        campaign: false,
        product: false
    }
};

export const slice = createSlice({
    name: 'glance',
    initialState,
    reducers: {
        setCached(state, action) {
            state.cached = action.payload
        },
        setCampaignPerformance(state, action) {
            state.campaign = action.payload
        },
        setProductPerformance(state, action) {
            state.product = action.payload
        },
        setLoading(state, action) {
            const {name, loading} = action.payload
            state.loading[name] = loading
        }
    }
});

export default slice.reducer;

export function getCampaignPerformance() {
    return async dispatch => {
        if (!store.getState().glance.cached) {
            dispatch(slice.actions.setLoading({name: 'campaign', loading: true}));
            try {
                const orgData = await getScript('At_A_Glance_CampaignPerformance');
                const newData = {
                    name: orgData['Campaign Name'],
                    roas: orgData['RoAS'],
                    score: orgData['Score'],
                    roasByRetailer: {
                        amazon: orgData['RoAS by Retailer']['ROAS']['Amazon'],
                        instacart: orgData['RoAS by Retailer']['ROAS']['Instacart'],
                        kroger: orgData['RoAS by Retailer']['ROAS']['Kroger'],
                        target: orgData['RoAS by Retailer']['ROAS']['Target'],
                        walmart: orgData['RoAS by Retailer']['ROAS']['Walmart'],
                    },
                    topRetailer: orgData['Top Retailer'],
                    topRetailerRoasByTactic: {
                        roas: {
                            "Offsite Display": orgData['Top Retailer RoAS by Tactic']['ROAS']['Offsite Display'],
                            "Onsite Display": orgData['Top Retailer RoAS by Tactic']['ROAS']['Onsite Display'],
                            "Search": orgData['Top Retailer RoAS by Tactic']['ROAS']['Search'],
                        }
                    },
                    recommendation: orgData['Recommendations'],
                }
                dispatch(slice.actions.setCampaignPerformance(newData));
            } catch (error) {
                console.log(error)
            }
            dispatch(slice.actions.setLoading({name: 'campaign', loading: false}));
            dispatch(slice.actions.setCached(true))
        }
    };
}

export function getProductPerformance() {
    return async dispatch => {
        if (!store.getState().glance.cached) {
            dispatch(slice.actions.setLoading({name: 'product', loading: true}));
            try {
                const orgData = await getScript('At_A_Glance_ProductSKUPerformance');
                const newData = {
                    title: orgData['Merchandising_Title'],
                    score: orgData['Agg_Score'],
                    retailerScores: {
                        amazon: orgData['Retailer Scores']['Amazon'],
                        kroger: orgData['Retailer Scores']['Kroger'],
                        target: orgData['Retailer Scores']['Target'],
                        walmart: orgData['Retailer Scores']['Walmart']
                    },
                    competingBrandScores: orgData['Competing Brand Scores'].map((item) => {
                        return ({
                            name: item[0],
                            value: item[1]
                        })
                    }),
                    recommendation: orgData['Text Recommendation']
                }
                dispatch(slice.actions.setProductPerformance(newData));
            } catch (error) {
                console.log(error)
            }
            dispatch(slice.actions.setLoading({name: 'product', loading: false}));
            dispatch(slice.actions.setCached(true))
        }
    };
}
