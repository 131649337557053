import axios from "axios";
import Queue from "promise-queue";
import { serverConfig } from "../config";

var queue = new Queue(1, 100);
 
    
/**
 * Return backend script API
 */
export const getScript = async (source, data = {}) => {
    const search_endpoint = "scripts";
    const endpoint = serverConfig.BACKEND_BASE + search_endpoint + "/" + source;
    //const result = await axios.post(endpoint, data);
    //return result.data;
    return queue.add(function () {
        console.log("Calling script at "+endpoint+" with data:", data);
        return axios.post(endpoint, data);
    }).then((results)=>{
        console.log("Response from script "+endpoint+": ", results);
        return results.data;
    });
}

/**
 * Get filter options
 * @param table
 * @param params
 * @returns {Promise<any>}
 */
export const getFilterOptions = async (table, params) => {
    /**
     *
     * @param params
     *       {
     *          LIMIT: "500",
     *          FIELDS: "campaign_name",
     *          DISTINCT: true
     *      }
     * @returns {string}
     *      LIMIT=500&FIELDS=campaign_name&DISTINCT=true
     */
    const formatParam = (params) => {
        return Object.entries(params).map(([k, v]) => `${k}=${v}`).join("&")
    }
    const search_endpoint = `database/search/${table}?${formatParam(params)}`;
    const endpoint = serverConfig.BACKEND_BASE + search_endpoint;
    const response = await axios.get(endpoint)
    return response.data
}
