import {
    amber,
    blue,
    cyan,
    deepOrange,
    deepPurple,
    green,
    indigo,
    lightBlue,
    lightGreen,
    lime,
    orange,
    pink,
    purple,
    red,
    teal,
    yellow
} from "@mui/material/colors";

const preDefinedColors = [
    amber[500],
    blue[500],
    cyan[500],
    deepOrange[500],
    deepPurple[500],
    green[500],
    indigo[500],
    lightBlue[500],
    lightGreen[500],
    lime[500],
    orange[500],
    pink[500],
    purple[500],
    red[500],
    yellow[500],
]
/**
 *
 * @param num
 * @param fixDigit
 * @returns {string}
 * @example:
 *      num: (65106780.775486) => "$65106k"
 */
export const formatPriceAmount = (num, fixDigit = 2) => {
    if (num === undefined || num === null) {
        return ''
    } else {
        if (num >= 1000000) {
            const milNum = num / 1000000;
            return `$${milNum.toFixed(fixDigit)}M`
        } else if (num >= 1000) {
            const kiloNum = num / 1000;
            return `$${kiloNum.toFixed(fixDigit)}K`
        } else {
            return `$${formatDecimalNum(num, fixDigit)}`
        }
    }
}

/**
 * Only keep last N digit decimal
 * @param num
 * @param fixDigit
 * @returns {string}
 * @example:
 *      (1.2235346) => "1.22"
 */
export const formatDecimalNum = (num, fixDigit = 2) => {
    if (num === null || num === undefined) {
        return ''
    } else {
        return num.toFixed(fixDigit)
    }

}

/**
 * Accept a percentage rate (decimal), return two function
 * @type {{color: ((function(*): (*))|*), text: ((function(*): string)|*)}}
 * @example:
 *      text:(0.0364259867934) => "36.425%"
 *      color: (0.0364259867934) => green[500]
 *      color: (-0.0364259867934) => red[500]
 */
export const getPercentage = {
    // Return a text function, which returns a percentage with limited decimal (with +/-)
    text: (num, times = 100, fixDigit = 2) => {
        if (num === null || num === undefined) {
            return ''
        } else {
            // if (num >= 0) {
            //     return `+${formatDecimalNum(num * times, fixDigit)}%`
            // } else {
                return `${formatDecimalNum(num * times, fixDigit)}%`
            // }
        }
    },
    // Return color (red/green) base on rate negative
    color: (num) => {
        if (num < 0) {
            return red[500]
        } else {
            return green[500]
        }
    }
}

/**
 * Capitalize
 * @param s
 * @returns {string}
 */
export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

/**
 * Generate positive hashCode for string
 * @returns {number}
 */
String.prototype.hashCode = function () {
    var hash = 0, i, chr;
    if (this.length === 0) return hash;
    for (i = 0; i < this.length; i++) {
        chr = this.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash);
};

/**
 * Return a unique color base on str hashCode, if it is a storeName, return preset color
 * @param str
 * @returns {*}
 */
export const strToHashColor = (str) => {
    if (str===undefined || str===null){
        return undefined
    }else {
        const storeNames = {
            'amazon': orange[500],
            'instacart': green[500],
            'kroger': indigo[500],
            'target': red[500],
            'walmart': yellow[500]
        }
        if (storeNames.hasOwnProperty(str.toLowerCase())) {
            return storeNames[str.toLowerCase()]
        }
        const code = str.hashCode()
        const index = code % preDefinedColors.length
        return preDefinedColors[index]
    }
}