import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    lastAuthName: "",
    lastAvator: "/static/img/avatars/sunray.PNG",
    lastPage: "/"
};
const SESSION = "session";

export const slice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        initialize(state, action) {
            var local = localStorage.getItem(SESSION);
            if(local){
                local = JSON.parse(local);
            }
            return local ?? initialState;
        },
        sessionSignIn(state, action) {
            const { nickname, picture } = action.payload;
            var newState = { 
              ...state, 
              lastAuthName: nickname, 
              lastAvator: picture 
            };
            console.log("Session signin event", JSON.stringify(newState));
            localStorage.setItem(SESSION, JSON.stringify(newState));
            return newState;
        },
        sessionSignOut(state, action) {

        },
        pageChanged(state, action) {
            const {pathname} = action.payload;
            var newState = { 
              ...state, 
              lastPage: pathname
            };
            console.log("Session pageChanged event", JSON.stringify(newState));
            localStorage.setItem(SESSION, JSON.stringify(newState));
            return newState;
        }
    }
});

export default slice.reducer;
export const { initialize, sessionSignIn, pageChanged } = slice.actions