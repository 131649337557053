import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import { useSelector, useDispatch } from "react-redux";
import { store } from "../../redux/store";
import {pageChanged} from "../../redux/slices/session";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { isAuthenticated } = useAuth();
  const dispatcher = useDispatch();
  const location = useLocation();

  if (isAuthenticated) {
    console.log("location changed", location);
    dispatcher(pageChanged(location));
    return <React.Fragment>{children}</React.Fragment>;
  }else{
    return <Navigate to="/auth/sign-in" />;
  }

}

export default AuthGuard;
