
export const getRetailerFieldName = (table) => {
    if(table === "retail_campaign_massaged"){
        return "retailer";
    }else if(table === "retailer_review_temp"){
        return "Retailer_Name";
    }else{
        return "retailer_name";
    }
}
export const getLineItemFieldName = (table) => {
    if(table === "retailer_review_temp" || table === "product_detail_page_temp"){
        return "product_name";
    }else{
        return "lineitem";
    }
}
export const readLocalFilters = (page) => {
    const localFilters = localStorage.getItem(page)
    return localFilters ? JSON.parse(localFilters) : {
        campaignName: "All",
        campaignLineitem: "All",
        retailerName: "All",
        tacticType: "All",
        brand: "All",
        season: "All",
        startDate: "2020-01-02",
        endDate: "2021-01-02",
    }
}
export const saveFilters = (page, filters) => {
    localStorage.setItem(page, JSON.stringify(filters))
}