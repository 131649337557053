import {createSlice} from '@reduxjs/toolkit';
import {store} from "../store";
import {getScript} from "../../utils/data";

const initialState = {
    cached: false,
    salesData: {
        brand: [],
        tactic: [],
        retailer: []
    },
    stat: {
        pIncrease: undefined,
        simulatedRevenueAmount: undefined,
        originalRevenueAmount: undefined,
        netNewDollars: undefined
    },
    dataFrame: [],
    loading: {
        stat : false,
        salesData: false,
        dataFrame: false
    },
};

export const slice = createSlice({
    name: 'campaign_optimizer',
    initialState,
    reducers: {
        setCached(state, action) {
            state.cached = action.payload
        },
        setSalesData(state, action) {
            state.salesData = action.payload
        },
        setStat(state, action) {
            state.stat = action.payload
        },
        setDataFrame(state, action) {
            state.dataFrame = action.payload
        },
        setLoading(state, action) {
            const {name, loading} = action.payload
            state.loading[name] = loading
        }       
    }
});

export default slice.reducer;
export const { initialize, sessionSignIn, pageChanged } = slice.actions

export function getCampaignOptimizerOutput() {
    return async dispatch => {
        if (!store.getState().campaign_optimizer.cached) {
            dispatch(slice.actions.setLoading({name: 'salesData', loading: true}));
            dispatch(slice.actions.setLoading({name: 'dataFrame', loading: true}));
            dispatch(slice.actions.setLoading({name: 'stat', loading: true}));
            try {
                const orgData = await getScript("Optimizer_Page_Outputs", {})
                console.log(orgData);
                dispatch(slice.actions.setSalesData({
                    brand: convertPieChartData(orgData['pie_brand']),
                    tactic: convertPieChartData(orgData['pie_tactic']),
                    retailer: convertPieChartData(orgData['pie_retailer'])
                }))

                dispatch(slice.actions.setStat({
                    pIncrease: orgData['p_increase'],
                    simulatedRevenueAmount: orgData['simulated_revenue_amount'],
                    originalRevenueAmount: orgData['original_revenue_amount'],
                    netNewDollars: orgData['net_new_dollars']
                }))

                const compaign_table = orgData['campaign_table'];
                const rows = [];
                for(var i = 0;i< compaign_table['CampaignIDs'].length; i++) {
                    rows.push({
                        id: i,
                        retailer: compaign_table['Retailer'][i],
                        campaignIDs: compaign_table["CampaignIDs"][i],
                        brand: compaign_table["primary_brand"][i],
                        tactic: compaign_table["Tactic"][i],
                        simulatedWeights: compaign_table["Simulated Weights"][i],
                        originalWeights: compaign_table["Original Weights"][i],
                        percentageDifference: compaign_table["Percentage Difference"][i]
                    })          
                }

                dispatch(slice.actions.setDataFrame(rows)
                )
            } catch (error) {
                console.log(error)
            }
            dispatch(slice.actions.setLoading({name: 'salesData', loading: false}));
            dispatch(slice.actions.setLoading({name: 'dataFrame', loading: false}));
            dispatch(slice.actions.setLoading({name: 'stat', loading: false}));

            dispatch(slice.actions.setCached(true))
        }
    };
}

function convertPieChartData(data) {
    const rows = [];
    const pie_data = data;
    const entries = Object.entries(pie_data['Original Weights']);
    for(var i=0; i< entries.length; i++) {
        rows.push({
            id: i,
            brand: entries[i][0],
            originalWeight: pie_data['Original Weights'][entries[i][0]],
            simulatedWeights: pie_data['Simulated Weights'][entries[i][0]],
            percentageDifference: pie_data['Percentage Difference'][entries[i][0]],
            percentDifference: pie_data['Percent Difference'][entries[i][0]],
        })
    }

    return rows;
}
