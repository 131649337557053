import {createSlice} from '@reduxjs/toolkit';
import {getScript} from "../../utils/data";
import {store} from "../store";
import {formatDecimalNum} from "../../utils/helpers";
import * as Util from "./util";

const initialState = {
    cached: false,
    filters: Util.readLocalFilters("hubFilters"),
    // StatPanel
    liveSales: {
        live: 0, sales: 0
    },
    leader: {
        product_name: undefined, agg_pdp_score: undefined
    },
    wordList: [],
    // Table
    campaignList: [],
    productList: [],
    // Chart
    trendData: [],
    performanceData: [],
    loading: {
        // StatPanel
        liveSales: false,
        leader: false,
        wordList: false,
        // Table
        campaignList: false,
        productList: false,
        // Chart
        trendData: false,
        performanceData: false,
    }
};

export const slice = createSlice({
    name: 'hub', initialState, reducers: {
        setCached(state, action) {
            state.cached = action.payload
        },
        setFilters(state, action) {
            state.cached = false
            state.filters = action.payload
            Util.saveFilters("hubFilters", action.payload)
        },
        // StatPanel
        setLiveSales(state, action) {
            state.liveSales = action.payload
        },
        setLeader(state, action) {
            state.leader = action.payload
        },
        setWordList(state, action) {
            state.wordList = action.payload
        },
        // Table
        setCampaignList(state, action) {
            state.campaignList = action.payload
        },
        setProductList(state, action) {
            state.productList = action.payload
        },
        // Chart
        setTrendData(state, action) {
            state.trendData = action.payload
        },
        setPerformanceData(state, action) {
            state.performanceData = action.payload
        },
        setLoading(state, action) {
            const {name, loading} = action.payload
            state.loading[name] = loading
        }
    }
});

export default slice.reducer;

const getFilterBody = (table = "retail_campaign_massaged") => {
    const message = []
    const campaignName = store.getState().hub.filters.campaignName
    if (campaignName !== "All") {
        message.push({
            "field": "campaign_name",
            "operator": "eq",
            "value": campaignName,
            "type": "string"
        })
    }

    const campaignLineitem = store.getState().hub.filters.campaignLineitem
    if (campaignLineitem !== "All") {
        message.push({
            "field": Util.getLineItemFieldName(table),
            "operator": "eq",
            "value": campaignLineitem,
            "type": "string"
        })
    }

    const retailerName = store.getState().hub.filters.retailerName;//Retailer_Name
    if (retailerName !== "All") {
        message.push({
            "field": Util.getRetailerFieldName(table),
            "operator": "eq",
            "value": retailerName,
            "type": "string"
        })
    }

    const tacticType = store.getState().hub.filters.tacticType
    if (tacticType !== "All") {
        message.push({
            "field": "tactice_site",
            "operator": "eq",
            "value": tacticType,
            "type": "string"
        })
    }

    const brandName = store.getState().hub.filters.brand
    if (brandName !== "All") {
        message.push({
            "field": "primary_brand",
            "operator": "eq",
            "value": brandName,
            "type": "string"
        })
    }
    const season = store.getState().hub.filters.season;
    console.log("seasons", season);
    if (season !== "All") {
        if (season == "Holiday"){
        }
        if (season == "Valentine's Day"){
            
        }
        if (season == "Easter"){
            
        }
        if (season == "Halloween"){
            
        }
    }
    /*
    const startDate = store.getState().hub.filters.startDate
    if (startDate !== "All") {
        message.push({
            "field": "startDate",
            "operator": "eq",
            "value": startDate,
            "type": "string"
        })
    }
    const endDate = store.getState().hub.filters.endDate
    if (endDate !== "All") {
        message.push({
            "field": "endDate",
            "operator": "eq",
            "value": endDate,
            "type": "string"
        })
    }
    */

    return {[table]: message}
}

/*
 *  StatPanel
 */
export function getLiveSalesData() {
    return async dispatch => {
        if (!store.getState().hub.cached) {
            dispatch(slice.actions.setLoading({name: 'liveSales', loading: true}));
            try {
                const orgData = await getScript('Hub_Page_HubLiveHubSales', getFilterBody());
                dispatch(slice.actions.setLiveSales({
                    live: orgData['Hub_Live'], sales: orgData['Hub_Sales']
                }));
            } catch (error) {
                console.log(error)
            }
            dispatch(slice.actions.setLoading({name: 'liveSales', loading: false}));
            dispatch(slice.actions.setCached(true))
        }
    }
}

export function getLeader() {
    return async dispatch => {
        if (!store.getState().hub.cached) {
            dispatch(slice.actions.setLoading({name: 'leader', loading: true}));
            try {
                const orgData = await getScript('Hub_Page_PDP_Leader', getFilterBody("product_detail_page_temp"));
                dispatch(slice.actions.setLeader({
                    product_name: Object.values(orgData['product_name'])[0],
                    agg_pdp_score: Object.values(orgData['agg_pdp_score'])[0]
                }));
            } catch (error) {
                console.log(error)
            }
            dispatch(slice.actions.setLoading({name: 'leader', loading: false}));
            dispatch(slice.actions.setCached(true))
        }
    }
}

export function getWordCloud() {
    const getFormattedData = (orgData) => {
        const newData = {}
        Object.keys(orgData['Words']).forEach((wordId) => {
            newData[orgData['Words'][wordId]] = orgData['Word_Count'][wordId]
        })
        return newData
    }

    const getNormalizedData = (orgData) => {
        const total = Object.values(orgData).reduce(function (a, b) {
            return a + b;
        }, 0);
        const newData = []
        Object.keys(orgData).forEach((word) => {
            newData.push({
                v: orgData[word] / total * 100, label: word
            })
        })
        return newData
    }

    return async dispatch => {
        if (!store.getState().hub.cached) {
            dispatch(slice.actions.setLoading({name: 'wordList', loading: true}));
            try {
                const orgData = await getScript('Hub_Page_Word_Cloud', getFilterBody("retailer_review_temp"));
                const formattedData = getFormattedData(orgData)
                const normalizedData = getNormalizedData(formattedData)
                dispatch(slice.actions.setWordList(normalizedData));
            } catch (error) {
                console.log(error)
            }
            dispatch(slice.actions.setLoading({name: 'wordList', loading: false}));
            dispatch(slice.actions.setCached(true))
        }
    }
}

/*
 *  Table
 */
export function getCampaignTable() {
    return async dispatch => {
        if (!store.getState().hub.cached) {
            dispatch(slice.actions.setLoading({name: 'campaignList', loading: true}));
            try {
                const orgData = await getScript('Hub_Page_HubRetailerCampaign', getFilterBody());
                const newCampaignList = Object.values(orgData).map((item) => {
                    return ({
                        campaignName: item['Campaign Name'],
                        retailerName: item['Retailer Name'],
                        imp: item['Imp.'],
                        rev: item['Rev.'],
                        score: formatDecimalNum(item['Score'], 1),
                        diffPercentage: item['%WoW Change'],
                    })
                })
                dispatch(slice.actions.setCampaignList(newCampaignList));
            } catch (error) {
                console.log(error)
            }
            dispatch(slice.actions.setLoading({name: 'campaignList', loading: false}));
            dispatch(slice.actions.setCached(true))
        }
    }
}

export function getProductTable() {
    const getFormattedData = (orgData) => {
        return (
            orgData['overall_score'].map((item) => {
                const result = {
                    name: item.product_name,
                    overallScore: item.agg_pdp_score
                }

                // Get OverallScore
                const scoreItems = orgData['pchange_overall_score'].filter(p => p.product_name === item.product_name)
                if(scoreItems.length > 1){
                    console.log("scoreItems", scoreItems);
                }
                if (scoreItems.length > 0) {
                    result.overallScoreRate = scoreItems[0].last_percent_diff
                }

                // Get Retailer Info
                const retailerItems = orgData['by_retailer_score'].filter(p => p.product_name === item.product_name)
                if(retailerItems.length > 1){
                    console.log("retailerItems", retailerItems);
                }
                if (retailerItems.length > 0) {
                    result.amazon = retailerItems[0].Amazon
                    result.kroger = retailerItems[0].Kroger
                    result.target = retailerItems[0].Target
                    result.walmart = retailerItems[0].Walmart
                }

                const retailerChangeItems = orgData['pchange_by_retailer_score'].filter(p => p.product_name === item.product_name)
                if(retailerChangeItems.length > 1){
                    console.log("retailerChangeItems", retailerChangeItems);
                }
                if (retailerChangeItems.length > 0) {
                    result.amazonRate = retailerChangeItems[0].Amazon
                    result.krogerRate = retailerChangeItems[0].Kroger
                    result.targetRate = retailerChangeItems[0].Target
                    result.walmartRate = retailerChangeItems[0].Walmart
                }
                return result
            })
        )
    }
    return async dispatch => {
        if (!store.getState().hub.cached) {
            dispatch(slice.actions.setLoading({name: 'productList', loading: true}));
            try {
                const orgData = await getScript('Hub_Page_PDP_Retailer_Score', getFilterBody("product_detail_page_temp"));
                const formattedData = getFormattedData(orgData)
                const newProductList = formattedData.map((item) => {
                    return ({
                        topRetailerProduct: item.name,
                        score1: {
                            value: formatDecimalNum(item.overallScore, 1),
                            diffPercentage: item.overallScoreRate,
                        },
                        walmartScore: {
                            value: formatDecimalNum(item.walmart),
                            diffPercentage: item.walmartRate,
                        },
                        amazonScore: {
                            value: formatDecimalNum(item.amazon),
                            diffPercentage: item.amazonRate,
                        },
                        targetScore: {
                            value: formatDecimalNum(item.target),
                            diffPercentage: item.targetRate,
                        },
                        krogerScore: {
                            value: formatDecimalNum(item.kroger),
                            diffPercentage: item.krogerRate,
                        },
                    })
                })
                dispatch(slice.actions.setProductList(newProductList));
            } catch (error) {
                console.log(error)
            }
            dispatch(slice.actions.setLoading({name: 'productList', loading: false}));
            dispatch(slice.actions.setCached(true))
        }
    };
}

/*
 *  Chart
 */
export function getTrendData() {
    return async dispatch => {
        if (!store.getState().hub.cached) {
            dispatch(slice.actions.setLoading({name: 'trendData', loading: true}));
            try {
                const orgData = await getScript('Hub_Page_Trend_Line', getFilterBody());
                dispatch(slice.actions.setTrendData(Object.entries(orgData['output table'])));
            } catch (error) {
                console.log(error)
            }
            dispatch(slice.actions.setLoading({name: 'trendData', loading: false}));
            dispatch(slice.actions.setCached(true))
        }
    }
}

export function getPerformanceData() {
    return async dispatch => {
        if (!store.getState().hub.cached) {
            dispatch(slice.actions.setLoading({name: 'performanceData', loading: true}));
            try {
                const orgData = await getScript('Hub_Page_Retailer_Seasonal', getFilterBody());
                dispatch(slice.actions.setPerformanceData(Object.values(orgData).map((item) => {
                    return ({
                        name: item['Retailer'],
                        thisYear: item['Rev.'],
                        lastYear: item['Rev. Last Year']
                    })
                })));
            } catch (error) {
                console.log(error)
            }
            dispatch(slice.actions.setLoading({name: 'performanceData', loading: false}));
            dispatch(slice.actions.setCached(true))
        }
    };
}
