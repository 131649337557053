import {
  BookOpen,
  Star,
  FileText,
  Award,
  Tag,
  Package,
  ThumbsUp,
  Activity,
  Sun
} from "react-feather";

const mochiSection = [
  {
    href: "/glance",
    icon: Sun,
    title: "At a Glance",
  },
  {
    href: "/mochi/hub",
    icon: Star,
    title: "Main Hub",
  },
  {
    href: "/mochi/campaign",
    icon: Award,
    title: "Campaign Hub"
  },
  {
    href: "/mochi/pdp",
    icon: Package,
    title: "PDP Hub"
  },
]
const detailsSection = [
  {
    href: "/details/pdp",
    icon: FileText,
    title: "PDP Detail"
  },
  {
    href: "/details/brand",
    icon: Tag,
    title: "Brand Detail"
  },
  {
    href: "/details/retailer",
    icon: ThumbsUp,
    title: "Retailer Detail"
  },
  {
    href: "/details/review",
    icon: BookOpen,
    title: "Keyword Review"
  },
]
const machineLearningSection = [
  {
    href: "/ml/campaign_optimizer",
    icon: Activity,
    title: "Campaign Optimizer"
  },
]


const navItems = [
  {
    title: "Mochi",
    pages: mochiSection,
  },
  {
    title: "AI Action Center",
    pages: machineLearningSection,
  },
  {
    title: "Detail",
    pages: detailsSection,
  },
];

export default navItems;
