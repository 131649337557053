import {createSlice} from '@reduxjs/toolkit';
import {getScript} from "../../utils/data";
import {store} from "../store";
import * as Util from "./util";

const initialState = {
    cached: false,
    filters: Util.readLocalFilters("pdpFilters"),
    metrics: {},
    campaignScore: [],
    productScore: [],
    loading: {
        metrics: false,
        campaignScore: false,
        productScore: false
    }
};

export const slice = createSlice({
    name: 'pdp',
    initialState,
    reducers: {
        setCached(state, action) {
            state.cached = action.payload
        },
        setFilters(state, action) {
            state.cached = false
            state.filters = action.payload
            Util.saveFilters("pdpFilters", action.payload)
        },
        setMetrics(state, action) {
            state.metrics = action.payload
        },
        setCampaignScore(state, action) {
            state.campaignScore = action.payload
        },
        setProductScore(state, action) {
            state.productScore = action.payload
        },
        setLoading(state, action) {
            const {name, loading} = action.payload
            state.loading[name] = loading
        }
    }
});

export default slice.reducer;
const getFilterBody = (table = "retail_campaign_massaged") => {
    const message = []
    const campaignName = store.getState().pdp.filters.campaignName
    if (campaignName !== "All") {
        message.push({
            "field": "campaign_name",
            "operator": "eq",
            "value": campaignName,
            "type": "string"
        })
    }

    const campaignLineitem = store.getState().pdp.filters.campaignLineitem
    if (campaignLineitem !== "All") {
        message.push({
            "field": Util.getLineItemFieldName(table),
            "operator": "eq",
            "value": campaignLineitem,
            "type": "string"
        })
    }

    const retailerName = store.getState().pdp.filters.retailerName
    if (retailerName !== "All") {
        message.push({
            "field": Util.getRetailerFieldName(table),
            "operator": "eq",
            "value": retailerName,
            "type": "string"
        })
    }

    const tacticType = store.getState().pdp.filters.tacticType
    if (tacticType !== "All") {
        message.push({
            "field": "tactice_site",
            "operator": "eq",
            "value": tacticType,
            "type": "string"
        })
    }

    const brandName = store.getState().pdp.filters.brand
    if (brandName !== "All") {
        message.push({
            "field": "primary_brand",
            "operator": "eq",
            "value": brandName,
            "type": "string"
        })
    }

    return {[table]: message}
}

export function getMetrics() {
    return async dispatch => {
        if (!store.getState().pdp.cached) {
            dispatch(slice.actions.setLoading({name: 'metrics', loading: true}));
            try {
                const orgData = await getScript('PDP_Metrics', getFilterBody());
                const newData = {
                    spend: orgData["spend"],
                    cpm: orgData["CPM"],
                    normalizedRoas: orgData["Normalized_ROAS"],
                }
                dispatch(slice.actions.setMetrics(newData));
            } catch (error) {
                console.log(error)
            }
            dispatch(slice.actions.setLoading({name: 'metrics', loading: false}));
            dispatch(slice.actions.setCached(true))
        }
    }
}

export function getCampaignScore() {
    return async dispatch => {
        if (!store.getState().pdp.cached) {
            dispatch(slice.actions.setLoading({name: 'campaignScore', loading: true}));
            try {
                const orgData = await getScript('PDP_Campaign_Scoring', getFilterBody());
                dispatch(slice.actions.setCampaignScore(orgData));
            } catch (error) {
                console.log(error)
            }
            dispatch(slice.actions.setLoading({name: 'campaignScore', loading: false}));
            dispatch(slice.actions.setCached(true))
        }
    }
}

export function getProductScore() {
    return async dispatch => {
        if (!store.getState().pdp.cached) {
            dispatch(slice.actions.setLoading({name: 'productScore', loading: true}));
            try {
                const orgData = await getScript('PDP_Individual_Product_Scoring', getFilterBody("product_detail_page_temp"));
                dispatch(slice.actions.setProductScore(orgData));
            } catch (error) {
                console.log(error)
            }
            dispatch(slice.actions.setLoading({name: 'productScore', loading: false}));
            dispatch(slice.actions.setCached(true))
        }
    }
}
