import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Button,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);


function SignIn() {
  const navigate = useNavigate();
  const { signIn, isAuthenticated } = useAuth();
  console.log("load login page", isAuthenticated);
  return (
    <Formik
      initialValues={{
        submit: false,
      }}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signIn();
          setSubmitting(false);
        } catch (error) {
          const message = error.message || "Something went wrong";
          console.log("sign in failed: ", error);
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Alert mt={3} mb={3} severity="info">
            Please use your <strong>Night Market</strong> account or your <strong>Corporate</strong>{" "}
            <strong>Client</strong> account to sign in! 
          </Alert>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="error">
              {errors.submit}
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Sign In
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignIn; 
