import {configureStore} from "@reduxjs/toolkit";
import glanceReducer from "./slices/glance";
import hubReducer from "./slices/hub";
import campaignReducer from "./slices/campaign";
import pdpReducer from "./slices/pdp";
import filtersReducer from "./slices/filters";
import counterReducer from "./slices/counter";
import sessionReducer from "./slices/session";
import campaignOptimizerReducer from "./slices/campaign_optimizer";

export const store = configureStore({
    reducer: {
        glance: glanceReducer,
        hub: hubReducer,
        campaign: campaignReducer,
        pdp: pdpReducer,
        session: sessionReducer,
        filters: filtersReducer,
        counter: counterReducer,
        campaign_optimizer: campaignOptimizerReducer
    },
});
