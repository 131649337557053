import {createSlice} from '@reduxjs/toolkit';
import {store} from "../store";
import {getFilterOptions} from "../../utils/data";

const initialState = {
    cached: {
        hub: false,
        campaign: false,
        pdp: false
    },
    hub: {
        campaignNames: [],
        campaignLineitems: [],
        retailerNames: [],
        tacticTypes: [],
        brands: [],
        seasons: [],
    },
    campaign: {
        campaignNames: [],
        campaignLineitems: [],
        retailerNames: [],
        tacticTypes: [],
        brands: [],
        seasons: [],
    },
    pdp: {
        campaignNames: [],
        campaignLineitems: [],
        retailerNames: [],
        tacticTypes: [],
        brands: [],
        seasons: [],
    }
}

export const slice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setCached(state, action) {
            const {target, cached} = action.payload
            state.cached[target] = cached
        },
        setHubOptions(state, action) {
            state.hub = {
                ...state.hub,
                ...action.payload,
            }
            state.cached.hub = true
        },
        setCampaignOptions(state, action) {
            state.campaign = {
                ...state.campaign,
                ...action.payload,
            }
            state.cached.campaign = true
        },
        setPdpOptions(state, action) {
            state.pdp = {
                ...state.pdp,
                ...action.payload,
            }
            state.cached.pdp = true
        },
    }
});

export default slice.reducer;

export function getHubOptions() {
    return async dispatch => {
        if (!store.getState().filters.cached.hub) {
            try {
                const campaignNames = await getFilterOptions("retail_campaign_massaged", {
                    LIMIT: "500",
                    FIELDS: "campaign_name",
                    DISTINCT: true
                })
                const campaignLineitems = await getFilterOptions("retail_campaign_massaged", {
                    LIMIT: "500",
                    FIELDS: "lineitem",
                    DISTINCT: true
                })
                const retailerNames = await getFilterOptions("retail_campaign_massaged", {
                    LIMIT: "500",
                    FIELDS: "retailer",
                    DISTINCT: true
                })
                const tacticTypes = await getFilterOptions("retail_campaign_massaged", {
                    LIMIT: "500",
                    FIELDS: "tactice_site",
                    DISTINCT: true
                })
                const brands = await getFilterOptions("retail_campaign_massaged", {
                    LIMIT: "500",
                    FIELDS: "primary_brand",
                    DISTINCT: true
                })
                const newOptions = {
                    campaignNames,
                    campaignLineitems,
                    retailerNames,
                    tacticTypes,
                    brands,
                    seasons: ["Holiday", "Valentine's Day", "Easter", "Halloween"],
                }
                dispatch(slice.actions.setHubOptions(newOptions));
            } catch (error) {
                console.log(error)
            }
        }
    }
}

export function getCampaignOptions() {
    return async dispatch => {
        if (!store.getState().filters.cached.campaign) {
            try {
                const campaignNames = await getFilterOptions("retail_campaign_massaged", {
                    LIMIT: "500",
                    FIELDS: "campaign_name",
                    DISTINCT: true
                })
                const campaignLineitems = await getFilterOptions("retail_campaign_massaged", {
                    LIMIT: "500",
                    FIELDS: "lineitem",
                    DISTINCT: true
                })
                const retailerNames = await getFilterOptions("retail_campaign_massaged", {
                    LIMIT: "500",
                    FIELDS: "retailer",
                    DISTINCT: true
                })
                const tacticTypes = await getFilterOptions("retail_campaign_massaged", {
                    LIMIT: "500",
                    FIELDS: "tactice_site",
                    DISTINCT: true
                })
                const brands = await getFilterOptions("retail_campaign_massaged", {
                    LIMIT: "500",
                    FIELDS: "primary_brand",
                    DISTINCT: true
                })
                const newOptions = {
                    campaignNames,
                    campaignLineitems,
                    retailerNames,
                    tacticTypes,
                    brands,
                    seasons: ["Holiday", "Valentine's Day", "Easter", "Halloween"],
                }
                dispatch(slice.actions.setCampaignOptions(newOptions));
            } catch (error) {
                console.log(error)
            }
        }
    }
}

export function getPdpOptions() {
    return async dispatch => {
        if (!store.getState().filters.cached.pdp) {
            try {
                const campaignNames = await getFilterOptions("retail_campaign_massaged", {
                    LIMIT: "500",
                    FIELDS: "campaign_name",
                    DISTINCT: true
                })
                const campaignLineitems = await getFilterOptions("retail_campaign_massaged", {
                    LIMIT: "500",
                    FIELDS: "lineitem",
                    DISTINCT: true
                })
                const retailerNames = await getFilterOptions("product_detail_page_temp", {
                    LIMIT: "500",
                    FIELDS: "retailer_name",
                    DISTINCT: true
                })
                const tacticTypes = await getFilterOptions("retail_campaign_massaged", {
                    LIMIT: "500",
                    FIELDS: "tactice_site",
                    DISTINCT: true
                })
                const brands = await getFilterOptions("product_detail_page_temp", {
                    LIMIT: "500",
                    FIELDS: "primary_brand",
                    DISTINCT: true
                })
                const newOptions = {
                    campaignNames,
                    campaignLineitems,
                    retailerNames,
                    tacticTypes,
                    brands,
                    seasons: ["Holiday", "Valentine's Day", "Easter", "Halloween"],
                }
                dispatch(slice.actions.setPdpOptions(newOptions));
            } catch (error) {
                console.log(error)
            }
        }
    }
}
