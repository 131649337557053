import { createContext, useEffect, useReducer } from "react";
import { Auth0Client } from "@auth0/auth0-spa-js";
import {store} from "../redux/store";
import { sessionSignIn } from "../redux/slices/session";
import { auth0Config } from "../config";
import {useDispatch} from "react-redux";
import { useNavigate } from 'react-router-dom';

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";

let auth0Client = null;

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user} = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }
  if (action.type === SIGN_IN) {
    const { user } = action.payload;
    return { 
      ...state, 
      isAuthenticated: true, 
      user, 
    };
  }
  if (action.type === SIGN_OUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const navigate = useNavigate();
  const dispatcher = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        auth0Client = new Auth0Client({
          client_id: auth0Config.clientId || "",
          domain: auth0Config.domain || "",
          redirect_uri: window.location.origin,
        });
        console.log("Auth0 initialize called");

        await auth0Client.checkSession();

        const isAuthenticated = await auth0Client.isAuthenticated();
        console.log("isAuthenticated: ", isAuthenticated);

        if (isAuthenticated) {
          const user = await auth0Client.getUser();
          console.log("load user", user);
          const user_verified = user && user.email_verified;
          console.log("user verified", user_verified);
          if(user_verified){
            dispatch({
              type: INITIALIZE,
              payload: { isAuthenticated: true, user: user || null },
            });
          }else{
            dispatch({
              type: INITIALIZE,
              payload: { isAuthenticated: false, user: user || null },
            });
          }

        } else {
          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated: false, user: null },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: { isAuthenticated: false, user: null },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async () => {
    console.log("sign in event started, calling popup");
    await auth0Client?.loginWithPopup({
      prompt: 'login'
    });
    const isAuthenticated = await auth0Client?.isAuthenticated();
    console.log("isAuthenticated returns: ", isAuthenticated);
    if (isAuthenticated) {
      const user = await auth0Client?.getUser();
      const user_verified = user && user.email_verified;
      console.log("user verified", user_verified);
      if(user_verified){
        dispatcher(sessionSignIn(user));
        dispatch({ type: SIGN_IN, payload: { user: user || null } });
        navigate(store.getState().session.lastPage);
      }else{
        throw {
          message: "User not verified, please checke email"
        }
      }
    }else{
      throw {
        message: "Authentication failed"
      }
    }
  };

  const signOut = () => {
    auth0Client?.logout();
    dispatch({ type: SIGN_OUT });
  };

  const resetPassword = (email) => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "auth0",
        user: {
          id: state?.user?.sub,
          avatar: state?.user?.picture,
          email: state?.user?.email,
          displayName: state?.user?.nickname,
          role: "user",
        },
        signIn,
        signOut,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
