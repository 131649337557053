import React, {useEffect}  from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {useDispatch, useSelector} from "react-redux";
import {initialize, getSession} from "../../redux/slices/session";

import { Avatar, Paper, Typography } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "../../components/auth/SignIn";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
`;

function Greeting(props) {
  const name = props.name;
  if (name) {
    return <p>Welcome back, {name}!</p>;
  }
  return <p>Welcome to Mochi!</p>;
}
function Avator(props) {
  const alt = props.alt ?? "Avator";
  const src = props.src ?? "/static/img/avatars/sunray.PNG";
  console.log(src);
  return <BigAvatar alt={alt} src={src} />;
}


function SignIn() {
  const lastSession = useSelector(state => state.session);
  console.log("lastSession", lastSession);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(initialize())
  }, [])


  return (
    <React.Fragment>
      <img src={process.env.PUBLIC_URL + '/new_logo.png'} style={{width: "200px"}}/> 
      <Wrapper>
        <Helmet title="Sign In" />
        <Avator alt={lastSession.lastAuthName} src={lastSession.lastAvator}/>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          <Greeting name={lastSession.lastAuthName}/>
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Sign in to your account to continue
        </Typography>

        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
